import React, { useEffect } from "react";
import Slider from "react-slick";
import { Container } from "react-grid-system";
import Particles from "react-tsparticles";
import "../App.css";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

function Intro() {
  const sliderSettings = {
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
  };
  const blobCursor = () => {  
    const CURSOR = document.querySelector('#cursorBlob');
    const LINKS = document.querySelectorAll('.glitch');
    const setCursorPos = (e) => {
      const { pageX: posX, pageY: posY } = e;
      CURSOR.style.top = `${posY - (CURSOR.offsetHeight / 2)}px`;
      CURSOR.style.left = `${posX - (CURSOR.offsetWidth / 2)}px`;
    };
    document.addEventListener('mousemove', setCursorPos);
    
    const setCursorHover = () => CURSOR.style.transform = 'scale(1.3)';
    const removeCursorHover = () => CURSOR.style.transform = '';
    LINKS.forEach(link => link.addEventListener('mouseover', setCursorHover));
    LINKS.forEach(link => link.addEventListener('mouseleave', removeCursorHover));
    
  };  
  useEffect(() => {
    blobCursor()
  }, [])

  return (
  

   <section className="section section-hero section-hero-4 display-fit-screen">
          <Particles
        params={{
          particles: {
            number: {
              value: 120,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
        className="el-particles"
      />
      <div className="background-container">
       
        <img
          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/moon2.png"
          alt=""
        />
        <div className="stars"></div>
        <div className="twinkling"></div>
        <div className="clouds"></div> <div id="cursorBlob"></div>
      </div>
      <div className="display-center">
        <Container>
          <div className="el-heading">
            <div className="el-icon-title">
              <div className="jt">
                <div className="jt__row jt__row--sibling" aria-hidden="true">
                  <div className="jt__text">Esra Haytaoglu</div>
                </div>
                <div className="jt__row jt__row--sibling" aria-hidden="true">
                  <div className="jt__text">Esra Haytaoglu</div>
                </div>
                <div className="jt__row jt__row--sibling" aria-hidden="true">
                  <div className="jt__text">Esra Haytaoglu</div>
                </div>
              </div>
            </div>

            <h1 className="glitch" data-text="Electrıcal Electronıcs Engıneer">
              Electrical Electronics Engineer
            </h1>
            <h5>
            Frontend developer, interested about React JS and React Native, constantly exploring new technologies.
            </h5>
            <Link to="/about">
              <div>
                <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Explore more</span>
                </button>
              </div>
            </Link>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Intro;
