
import * as types from "./types";
import * as api from "../api/index"


export const fetchPosts = () => async (dispatch) => {
    dispatch({ type: types.FETCH_POSTS_START });
    try {
        const {data}  = await api.fetchPosts();
        dispatch({
            type: types.FETCH_POSTS,
            payload: data,
        });
    } catch {
        dispatch({ type: types.FETCH_POSTS_ERROR})
    }
};
export const fetchSinglePost = (id)=> async (dispatch) => {
    dispatch({ type: types.FETCH_SINGLE_POST_START });
    try {
        const { data } = await api.fetchSinglePost(id);
        dispatch({
            type: types.FETCH_SINGLE_POST,
            payload: data,
        })
    } catch {
        dispatch({ type: types.FETCH_SINGLE_POST_ERROR})
    }
}

export const createPost = (post)=> async (dispatch) => {
    dispatch({ type: types.CREATE_POST_START });
    try {
        const { data } = await api.createPost(post);
        dispatch({
            type: types.CREATE_POST,
            payload: data,
        })
    } catch {
        dispatch({ type: types.CREATE_POST_ERROR });
    }
}


export const deletePost = (id)=> async (dispatch) => {
    dispatch({ type: types.DELETE_POST_START });
    try {
        const { data } = await api.deletePost(id);
        dispatch({
            type: types.DELETE_POST_SUCCESS,
            payload: data._id,
        })
    } catch {
        dispatch({ type: types.DELETE_POST_ERROR });
    }
}

// export const updatePost = (id, post)=> async (dispatch) => {
//     dispatch({ type: types.UPDATE_POST_START });
//     try {
//         const { data } = await api.updatePost(id, post);
//         dispatch({
//             type: types.UPDATE_POST_SUCCESS,
//             payload: data,
//         })
//     } catch {
//         dispatch({ type: types.UPDATE_POST_ERROR });
//     }
// }