import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";
import { FaReact, FaCss3Alt } from "react-icons/fa";
import { DiJavascript1 } from "react-icons/di";
import { SiTypescript } from "react-icons/si";
import { BsFillBootstrapFill } from "react-icons/bs";
import { GrNode } from "react-icons/gr";
import AboutAnimation from "../lottie/About/About";
import "../App.css";

function About() {
  return (
    <section className="section section-about section-about-1">
      <div className="display-spacing">
        <Container>
          <div className="about-content">
            <Row>
              <Col lg={2}>
                {" "}
                <AboutAnimation />
              </Col>
              <Col lg={10}>
                <header className="el-heading">
                  <h2 className="el-icon">
                    <span className="el-icon-title">My, Myself & I</span>
                  </h2>
                  <h3>
                    <span>I am Esra.</span>
                  </h3>
                  <div className="divider divider-1-reverse divider-1-1"></div>
                  <div className="divider divider-1-reverse divider-1-2"></div>
                  <p>
                  I am currently working in a bank. I am also interested in mobile and website development. I have serious passion for UI effects, animations and creating intuitive, dynamic user experiences. The main area of ​​my expertise is front-end development.
                    <br />
                    <a href="/contact">Let's make something special.</a>
                  </p>
                </header>
              </Col>
            </Row>
            <Row className="pt-30">
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <DiJavascript1 className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5> JavaScript </h5>
                    <p>Javascirpt is the basic programming language I use.</p>
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <FaReact className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5>React</h5>
                    <blockquote>
                      "Learn React Once and Write Everywhere" - Reactjs.org
                    </blockquote>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <FaReact className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5>React Native</h5>
                    <blockquote>
                      Build mobile apps with React.
                    </blockquote>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <FaCss3Alt className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5> CSS </h5>
                    <p>
                      CSS is the language I use to style an HTML document and
                      Scss.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <BsFillBootstrapFill className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5> Bootstrap </h5>
                    <p>
                      I quickly design responsive mobile-first sites with
                      Bootstrap, the world’s most popular front-end open source
                      toolkit.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <SiTypescript className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5> Typescript </h5>
                    <p>
                      TypeScript is a strongly typed programming language that
                      builds on JavaScript, giving you better tooling at any
                      scale. So now I developing my projects with typescript.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="el-card-icon">
                  <div className="el-icon">
                    <GrNode className="el-icon-icon" />
                  </div>
                  <div className="content">
                    <h5> Node JS </h5>
                    <p>I'm using Node js and Mongo DB on the backend.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default About;
