import { Routes, Route } from "react-router-dom";


import './App.css';
import About from "./components/About";
import Blog from "./components/Blog";
import BlogSinglePost from "./components/BlogSinglePost";
import Contact from "./components/Contact";
import Error404 from "./components/Error404";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Projects from "./components/Projects";
import Service from "./components/Service";

function App() {
  return (
    <div >
      <Header />
      <Routes>
    <Route path="/" element={<Intro />} />
    <Route path="*" element={<Error404 />} />
    <Route path="/about" element={<About />} />
    <Route path="/blog" element={<Blog />} />
    <Route path="/blog/:id" element={<BlogSinglePost />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/projects" element={<Projects />} />
    <Route path="/service" element={<Service />} />

    </Routes>
    </div>
  );
}

export default App;
