import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import emailjs from "emailjs-com";


import "../App.css";
import ContactAnimation from "../lottie/Contact/Contact";

function Contact() {
  const form = useRef();
  const [send, setSend] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();
    if(
      form.current[0].value !== ""
    ) {
      
    emailjs
    .sendForm(
      "service_hze3tto",
      "template_0zl67bq",
      form.current,
      "user_4JBn65ZXzqazHuAVvW4Mx"
    )
    .then(
      (result) => {
        setSend(true);
        
      },
      (error) => {
        console.log(error.text);
      }
    );

  e.target.reset();
    }

  };
 
  return (
    <section className="section section-contact section-contact-1">
      <div className="display-spacing">
        <Container>
              <div className="contact-content">
                <Row>
                  <Col sm={3} xs={4}><ContactAnimation /></Col>
                  <Col   sm={9} xs={8}>  <header className="el-heading">
                  <h2 className="el-icon">
                    
                    <span className="el-icon-title">CONTACT ME</span>
                  </h2>
                  <h3>
                    <span>Get in touch with me today.</span>
                  </h3>
                  <div className="divider divider-1-reverse divider-1-1"></div>
                  <div className="divider divider-1-reverse divider-1-2"></div>
                </header></Col>
                </Row>
              
                <form
                  className="form form-1 pt-15"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <Row className="row">
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input type="text" name="name" id="name" required />
                        <label htmlFor="form-item-name">Your Name</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input type="email" name="email" id="email" required />
                        <label htmlFor="form-item-email">Your Email</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <div className="form-item">
                        <textarea id="message" name="message" required></textarea>
                        <label htmlFor="form-item-message">Your Message</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <div class="wrapper-no4">
                        <button
                          class={`button-bird ${send && "active"}`}
                       
                        >
                          <p class="button-bird__text">Send Message ! </p>
                          <svg
                            version="1.1"
                            class="feather"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            z="1"
                            viewBox="0 0 75 38"
                            styles="enable-background:new 0 0 75 38;"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M20.8,31.6c3.1-0.7,2.9-2.3,2,1c9.1,4.4,20.4,3.7,29.1-0.8l0,0c0.7-2.1,1-3.9,1-3.9c0.6,0.8,0.8,1.7,1,2.9
                c4.1-2.3,7.6-5.3,10.2-8.3c0.4-2.2,0.4-4,0.4-4.1c0.6,0.4,0.9,1.2,1.2,2.1c4.5-6.1,5.4-11.2,3.7-13.5c1.1-2.6,1.6-5.4,1.2-7.7
                c-0.5,2.4-1.2,4.7-2.1,7.1c-5.8,11.5-16.9,21.9-30.3,25.3c13-4,23.6-14.4,29.1-25.6C62.8,9,55.6,16.5,44.7,20.7
                c2.1,0.7,3.5,1.1,3.5,1.6c-0.1,0.4-1.3,0.6-3.2,0.4c-7-0.9-7.1,1.2-16,1.5c1,1.3,2,2.5,3.1,3.6c-1.9-0.9-3.8-2.2-5.6-3.6
                c-0.9,0.1-10.3,4.9-22.6-12.3C5.9,17.7,11.8,26.9,20.8,31.6z"
                              />
                            </g>
                          </svg>
                          <span class="bird"></span>
                          <span class="bird--1"></span>
                          <span class="bird--2"></span>
                          <span class="bird--3"></span>
                          <span class="bird--4"></span>
                          <span class="bird--5"></span>
                          <span class="bird--6"></span>
                          <span class="bird--7"></span>
                          <span class="bird--8"></span>
                          <span class="bird--9"></span>
                          <span class="bird--10"></span>
                          <span class="bird--11"></span>
                          <span class="bird--12"></span>
                          <span class="bird--13"></span>
                          <span class="bird--14"></span>
                          <span class="bird--15"></span>
                          <span class="bird--16"></span>
                          <span class="bird--17"></span>
                          <span class="bird--18"></span>
                          <span class="bird--19"></span>
                          <span class="bird--20"></span>
                          <span class="bird--21"></span>
                          <span class="bird--22"></span>
                          <span class="bird--23"></span>
                          <span class="bird--24"></span>
                          <span class="bird--25"></span>
                          <span class="bird--26"></span>
                          <span class="bird--27"></span>
                          <span class="bird--28"></span>
                          <span class="bird--29"></span>
                          <span class="bird--30"></span>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
        </Container>
      </div>
    </section>
  );
}

export default Contact;
