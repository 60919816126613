import React, { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import { RiArrowGoBackFill } from "react-icons/ri";
import { Container } from "react-grid-system";
import {useNavigate, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


import { fetchSinglePost } from "../actions/posts";
import Loading from "../lottie/Loading/Loading";


function BlogSinglePost() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id  } = useParams();

    const currentPost = useSelector((state) => state.posts.currentPost);
    const loading = useSelector((state) => state.posts.loading);
  useEffect(() => {
    dispatch(fetchSinglePost(id));
  }, [dispatch]);

    const handleClick = () => {
        navigate('/blog')
    }

    const convertRelativeTime = (date) => {
      return moment(date).fromNow();
    };

  return (
    <section className="section section-single-post section-single-post-1">
      <div className="display-spacing">
      {loading ? <Loading /> : (
          <Container>
          <div className="max-w-768 div-center">
            <div className="post">
              <div className="post-head">
                <div class="post-info">
                  <button
                    className="button button-go-back el-go-back"
                    onClick={handleClick}
                  >
                    <RiArrowGoBackFill />
                    <span className="text">Back</span>
                  </button>
                  <h1>{currentPost?.title}</h1>
                  <p>
                    by <span className="author">Esra</span> in{" "}
                    <span className="category">{currentPost?.tag}</span> on{" "}
                    <span class="date"> {convertRelativeTime(currentPost?.createdAt)}</span>.
                  </p>
                </div>
              </div>
              <div className="post-body">
                <div className="post-content">
                  <p>
                    {currentPost?.subtitle}
                  </p>
                  <p>
                    <b>Key Features:</b>
                  </p>
                  <ul class="el-list-check">
                    <li>
                      <FaCheck className="icon" />
                      Software
                    </li>
                    <li>
                      <FaCheck className="icon" />
                      Frontend 
                    </li>
                    <li>
                      <FaCheck className="icon" />
                      Web technologies
                    </li>
                    <li>
                      <FaCheck className="icon" />
                      Javascript
                    </li>
                  </ul>
                  <p>
                  {currentPost?.content}
                  </p>
                 
                </div>
                <div class="post-tags">
                  <span>Tags:</span>
                  <ul>
                    <li>
                      software
                    </li>
                    <li>
                      web development
                    </li>
                 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>

      )
}
      
      </div>
    </section>
  );
}

export default BlogSinglePost;
