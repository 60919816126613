import * as types from "../actions/types";

const INITIAL_STATE = {
  postList: [],
  loading: false,
  error: "",
  currentPost: null,
};

export const postReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_POSTS_START:
      return { ...state, loading: true, error: "" };
    case types.FETCH_POSTS:
      return { ...state, postList: action.payload, loading: false, error: "" };
    case types.FETCH_POSTS_ERROR:
      return { ...state, loading: false, error: "Error fetching posts" };

    case types.FETCH_SINGLE_POST_START:
      return { ...state, loading: true, error: "" };
    case types.FETCH_SINGLE_POST:
      return {
        ...state,
        currentPost: action.payload,
        loading: false,
        error: "",
      };
    case types.FETCH_SINGLE_POST_ERROR:
      return { ...state, loading: false, error: "Error fetching post" };

    case types.CREATE_POST_START:
      return { ...state, loading: true, error: "" };
    case types.CREATE_POST:
      return {
        ...state,
        loading: false,
        error: "",
        postList: [...this.state.postList, action.payload],
      };
    case types.CREATE_POST_ERROR:
      return { ...state, loading: false, error: "Error creating post" };

    case types.DELETE_POST_START:
      return { ...state, loading: true, error: "" };
    case types.DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        postList: state.postList.filter((post) => post.id !== action.payload),
      };
    case types.DELETE_POST_ERROR:
      return { ...state, loading: false, error: "Error deleting post" };

    case types.UPDATE_POST_START:
      return { ...state, loading: true, error: "" };
    case types.UPDATE_POST_SUCCESS:
      return {
        ...state,
        error: "",
        postList: state.postList.map((post) => {
          if (post._id === action.payload._id) {
            return action.payload;
          } else {
            return post;
          }
        }),
      };
    case types.UPDATE_POST_ERROR:
      return { ...state, loading: false, error: "Error updating post" };
    default:
      return state;
  }
};
