import React from "react";
import { Link } from "react-router-dom";
import ErrorAnimation from "../lottie/Error/Error"

function Error404() {
    return (
        <section className="section section-error-404 overlay-image">
            <div className="display-center">
                <div className="error-content text-center">
                    <ErrorAnimation />
                    <h3>Page not found</h3>
                    <Link to="/">
                    <button className="learn-more">
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text"> Homepage</span>
                </button>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Error404;

