import React, { useEffect } from 'react';
import Lottie from "react-lottie";
import * as about from "./frontend.json"

function FrontendAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: about.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return (
        <div >
        <Lottie options={defaultOptions} height={150} width={150} />
    </div>
    )
}

export default FrontendAnimation;
