import React from 'react';
import Lottie from "react-lottie";
import * as mongo from "./mongo.json"

function MongoAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mongo.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return (
        <div >
        <Lottie options={defaultOptions} height={150} width={150} />
    </div>
    )
}

export default MongoAnimation;
