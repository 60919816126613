import axios from "axios";

const apiEndpoint = "https://esra-blog-backend.herokuapp.com/posts/";

export const fetchPosts = async () => await axios.get(apiEndpoint);

export const createPost = async (post) => await axios.post(apiEndpoint, post)


export const deletePost = async (id) => await axios.delete(`${apiEndpoint}${id}`);


export const updatePost = async (id, updatedPost) => await axios.patch(`${apiEndpoint}${id}`, updatedPost);


export const fetchSinglePost = async (id) => await axios.get(`${apiEndpoint}${id}`);