import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Carousel, { Modal, ModalGateway } from "react-images";
import one from "../images/1.JPG";
import two from "../images/2.JPG"
import three from "../images/3.JPG"
import four from "../images/4.JPG"
import five from "../images/5.JPG"
import six from "../images/6.JPG"
import seven from "../images/7.JPG"
import eight from "../images/8.JPG"
import nine from "../images/9.JPG"
import ten from "../images/10.JPG"
import eleven from "../images/11.jpg"
import twelve from "../images/12.JPG"
import thirteen from "../images/13.JPG"
import fourteen from "../images/14.JPG"
import fifteen from "../images/15.JPG"

// Components
import Headline from "./common/Headline";
import { Link } from "react-router-dom";

const portfolio = [
    {
        name: "All Worls",
        items: [
            {
                id: 0,
                title: "Delicious Recipes",
                link: "https://github.com/EsraCebii/Delicious-Recipes",
                subtitle: "React, Redux, React-Router, Typescript, Redux-Thunk",
                src: one,
                thumbnail: one,
            },
            {
                id: 1,
                title: "Expense Tracker App",
                link: "https://github.com/EsraCebii/Expense-Tracker-App",
                subtitle: "React, Redux, React-Router, Typescript, ANTD",
                src: two,
                thumbnail: two,
            },
            {
                id: 2,
                title: "My Blog",
                link: "https://github.com/EsraCebii/myBlog",
                subtitle: "React, Redux, Nodejs, Express, Mongo Atlas",
                src: three,
                thumbnail: three,
            },
            {
                id: 3,
                title: "Rock, Paper, Scissors",
                subtitle: "React",
                link: "https://github.com/EsraCebii/Rock-Paper-Scissors",
                src: four,
                thumbnail: four,
            },
            {
                id: 4,
                title: "Blogium",
                subtitle: "React, Redux, React-Router, Bootstrap, CRUD",
                link: "https://github.com/EsraCebii/Blogium",
                src: five,
                thumbnail: five,
            },
            {
                id: 5,
                title: "Covid-19 Tracker",
                subtitle:  "React, React-Redux, Redux-Toolkit, React Chart, Material UI",
                link: "https://github.com/EsraCebii/Covid-19-Tracker",
                src: six,
                thumbnail: six,
            },
            {
                id: 6,
                title: "Clean Blog",
                subtitle:  "Nodejs, Express, Mongo DB, mongoose, Template Engine",
                link: "https://github.com/EsraCebii/CleanBlog",
                src: seven,
                thumbnail: seven,
            },
            {
                id: 7,
                title: "WhazzApp",
                subtitle: "React, Axios, Chat Engine",
                link: "https://github.com/EsraCebii/WhazzApp",
                src: eight,
                thumbnail: eight,
            },
            {
                id: 8,
                title: "Note's App",
                subtitle: "React, React-Redux, Redux-Thunk, React-Router",
                link: "https://github.com/EsraCebii/NotesApp",
                src: nine,
                thumbnail: nine,
            },
            {
                id: 9,
                title: "Breaking Bad",
                subtitle:  "React, React-Redux, Redux-Thunk React-Router",
                link: "https://github.com/EsraCebii/Breaking-Bad",
                src: ten,
                thumbnail: ten,
            },
            {
                id: 10,
                title: "Jungle Memory",
                subtitle: "React, Material UI, Bootstrap",
                link: "https://github.com/EsraCebii/Jungle-Memory",
                src: eleven,
                thumbnail: eleven,
            },
            {
                id: 11,
                title: "Spend Money",
                subtitle: "React, React-Redux Reduxjs Toolkit, Material UI",
                link: "https://github.com/EsraCebii/elon-musk",
                src: twelve,
                thumbnail: twelve,
            },
            {
                id: 12,
                title: "Smart Edu",
                subtitle: "Nodejs, Express, Nodemon,Nodemailer, Slugify, mongoose",
                link: "https://github.com/EsraCebii/SmartEdu",
                src: thirteen,
                thumbnail: thirteen,
            },
            {
                id: 13,
                title: "Dumbo's Shop",
                subtitle: "React, React-Query, Context Api, Formik, Mock Api, Semantic UI, Chakra UI",
                link: "https://github.com/EsraCebii/dumbos-shop",
                src: fourteen,
                thumbnail: fourteen,
            },
            {
                id: 14,
                title: "Weather App",
                subtitle: "React, Context Api",
                link: "https://github.com/EsraCebii/hava-durumu",
                src: fifteen,
                thumbnail: fifteen,
            },
        ],
    },
    {
        name: "Redux",
        items: [
            {
                id: 4,
                title: "Blogium",
                subtitle:"React, Redux, React-Router, Bootstrap, CRUD",
                link: "https://github.com/EsraCebii/Blogium",
                src: five,
                thumbnail: five,
            },
            {
                id: 5,
                title: "Covid-19 Tracker",
                subtitle: "React, React-Redux, Redux-Toolkit, React Chart, Material UI",
                link: "https://github.com/EsraCebii/Covid-19-Tracker",
                src: six,
                thumbnail: six,
            },
            {
                id: 8,
                title: "Note's App",
                subtitle:  "React, React-Redux, Redux-Thunk, React-Router",
                link: "https://github.com/EsraCebii/NotesApp",
                src: nine,
                thumbnail: nine,
            },
            {
                id: 9,
                title: "Breaking Bad",
                subtitle:  "React, React-Redux, Redux-Thunk React-Router",
                link: "https://github.com/EsraCebii/Breaking-Bad",
                src: ten,
                thumbnail: ten,
            },
            {
                id: 0,
                title: "Delicious Recipes",
                link: "https://github.com/EsraCebii/Delicious-Recipes",
                subtitle: "React, Redux, React-Router, Typescript, Redux-Thunk",
                src: one,
                thumbnail: one,
            },
            {
                id: 1,
                title: "Expense Tracker App",
                link: "https://github.com/EsraCebii/Expense-Tracker-App",
                subtitle: "React, Redux, React-Router, Typescript, ANTD",
                src: two,
                thumbnail: two,
            },
            {
                id: 2,
                title: "My Blog",
                link: "https://github.com/EsraCebii/myBlog",
                subtitle: "React, Redux, Nodejs, Express, Mongo Atlas",
                src:three,
                thumbnail: three,
            },
            {
                id: 11,
                title: "Spend Money",
                subtitle: "React, React-Redux Reduxjs Toolkit, Material UI",
                link: "https://github.com/EsraCebii/elon-musk",
                src: twelve,
                thumbnail: twelve,
            },
        ],
    },
    {
        name: "Typescript",
        items: [
            {
                id: 0,
                title: "Delicious Recipes",
                link: "https://github.com/EsraCebii/Delicious-Recipes",
                subtitle: "React, Redux, React-Router, Typescript, Redux-Thunk",
                src: one,
                thumbnail: one,
            },
            {
                id: 1,
                title: "Expense Tracker App",
                link: "https://github.com/EsraCebii/Expense-Tracker-App",
                subtitle: "React, Redux, React-Router, Typescript, ANTD",
                src: two,
                thumbnail: two,
            },
        
        ],
    },
    {
        name: "Game",
        items: [
            {
                id: 3,
                title: "Rock, Paper, Scissors",
                subtitle:  "React",
                link: "/portfolio-details",
                src: four,
                thumbnail: four,
            },
            {
                id: 11,
                title: "Spend Money",
                subtitle: "React, React-Redux Reduxjs Toolkit, Material UI",
                link: "https://github.com/EsraCebii/elon-musk",
                src: twelve,
                thumbnail: twelve,
            },
            {
                id: 10,
                title: "Jungle Memory",
                subtitle:"React, Material UI, Bootstrap",
                link: "https://github.com/EsraCebii/Jungle-Memory",
                src: eleven,
                thumbnail: eleven,
            },
        ],
    },
    {
        name: "Nodejs",
        items: [
            {
                id: 12,
                title: "Smart Edu",
                subtitle: "Nodejs, Express, Nodemon,Nodemailer, Slugify, mongoose",
                link: "https://github.com/EsraCebii/SmartEdu",
                src: thirteen,
                thumbnail: thirteen,
            },
            {
                id: 6,
                title: "Clean Blog",
                subtitle:  "Nodejs, Express, Mongo DB, mongoose, Template Engine",
                link: "https://github.com/EsraCebii/CleanBlog",
                src: seven,
                thumbnail:seven,
            },
        ],
    },
    {
        name: "MERN",
        items: [
            {
                id: 2,
                title: "My Blog",
                link: "https://github.com/EsraCebii/myBlog",
                subtitle:"React, Redux, Nodejs, Express, Mongo Atlas",
                src: three,
                thumbnail: three,
            },
        ],
    },
];

const images = [
    {
        src: one
    },
    {
        src: two,
    },
    {
        src: three,
    },
    {
        src: four,
    },
    {
        src: five,
    },
    {
        src: six,
    },
    {
        src: seven,
    },
    {
        src: eight,
    },
    {
        src: nine,
    },
    {
        src: ten,
    },
    {
        src: eleven,
    },
    {
        src: twelve,
    },
    {
        src: thirteen,
    },
    {
        src: fourteen,
    },
    {
        src: fifteen,
    },
];

function Projects() {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const toggleLightbox = (index) => {
        setSelectedIndex(index);
        setLightboxIsOpen(!lightboxIsOpen);
    };

    return (
        <section className="section section-portfolio section-portfolio-1">
            <div className="display-spacing">
                <Container className="container">
                    <Headline label="Projects" title="Let's See My Projects" divider_1={true} position="center" />
                    <Tabs className="el-tabs el-tabs-1" selectedTabClassName="active">
                        <TabList className="el-tabs-links">
                            {portfolio.map((category, categoryIndex) => (
                                <Tab key={categoryIndex}>{category.name}</Tab>
                            ))}
                        </TabList>
                        {portfolio.map((category, categoryIndex) => (
                            <TabPanel key={categoryIndex}>
                                <Row className="row-center mb--30">
                                    {category.items.map((item, index) => (
                                        <Col key={index} xs={6} sm={6} md={6} lg={3} xl={3} >
                                            <div className="portfolio-item">
                                                <div className="portfolio-card">
                                                    <div className="image overlay-image" style={{ backgroundImage: `url(${item.src})` }} />
                                                    <div className="content">
                                                        <button className="button button-circle button-icon button-circle-md button-primary" onClick={() => toggleLightbox(item.id)}>
                                                            <span className="el-icon-icon bi bi-arrows-fullscreen"></span>
                                                        </button>
                                                        <h3>{item.title}</h3>
                                                        <span>{item.subtitle}</span>
                                                        <a href={item.link} target="_blank">
                                                            <button className=" button-git el-icon-icon bi bi-github"></button>
                                                        </a>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </TabPanel>
                        ))}
                    </Tabs>
                </Container>
                <ModalGateway>
                    {lightboxIsOpen ? (
                        <Modal onClose={toggleLightbox}>
                            <Carousel currentIndex={selectedIndex} views={images} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        </section>
    );
}

export default Projects;
