import React from 'react';
import Lottie from "react-lottie";
import * as loading from "./loadingme.json"

function Loading() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loading.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return (
        <div>
      
            <Lottie options={defaultOptions} height={300} width={300} />
            <h5 className='thinking'>Esra is thinking...</h5>
        </div>
    )
}

export default Loading;
