
export const FETCH_POSTS_START = "FETCH_POSTS_START"
export const FETCH_POSTS = "FETCH_POSTS"
export const FETCH_POSTS_ERROR = "FETCH_POSTS_ERROR"


export const FETCH_SINGLE_POST_START = "FETCH_SINGLE_POST_START"
export const FETCH_SINGLE_POST = "FETCH_SINGLE_POST"
export const FETCH_SINGLE_POST_ERROR = "FETCH_SINGLE_POST_ERROR"



export const CREATE_POST_START = "CREATE_POST_START"
export const CREATE_POST = "CREATE_POST"
export const CREATE_POST_ERROR = "CREATE_POST_ERROR"



export const SEND_EMAIL = "SEND_EMAIL"



export const DELETE_POST_START = "DELETE_POST_START"
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS"
export const DELETE_POST_ERROR = "DELETE_POST_ERROR"

export const UPDATE_POST_START = "UPDATE_POST_START"
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS"
export const UPDATE_POST_ERROR = "UPDATE_POST_ERROR"