import React, { useEffect } from "react";
import Slider from "react-slick";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AiOutlineTags } from "react-icons/ai";

import { fetchPosts } from "../actions/posts";
import Headline from "./common/Headline";
import "../App.css";
import Loading from "../lottie/Loading/Loading";

function Blog() {
  const dispatch = useDispatch();
  let sliderSettings = {
    dots: true,
    speed: 500,
    arrows: false,
    infinite: false,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerPadding: 30,
    draggable: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const posts = useSelector((state) => state.posts.postList);
  const loading = useSelector((state) => state.posts.loading);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);
  const convertRelativeTime = (date) => {
    return moment(date).fromNow();
  };
  return (
    <section className="section section-blog section-blog-1">
      <div className="display-spacing">
        <Container className="container">
          <Headline
            label="Blog"
            title="Let's See My Notes"
            divider_1={true}
            position="center"
          />
          {loading ? <Loading /> : (
                <Slider className="el-slider el-slider-plr--15" {...sliderSettings}>
                {posts
                  .sort((a, b) =>
                    convertRelativeTime(a.createdAt) >
                    convertRelativeTime(b.createdAt)
                      ? 1
                      : -1
                  )
                  .map(
                    (item, index) =>
                      item.title && (
                        <div key={index} className="post-item">
                      
                          <div className="post-content">
                            <div className="post-data">
                              <h4 className="post-title">
                                <Link to={`/blog/${item._id}`}>{item.title}</Link>
                              </h4>
                              <p className="post-title">
                                {item.content.substring(0, 100) + "..."}
                              </p>
                              <div className="post-date">
                                <i className="ti-time"></i>
                                {convertRelativeTime(item.createdAt)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </Slider>
          )}
        
        </Container>
      </div>
    </section>
  );
}

export default Blog;
